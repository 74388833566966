export default (project: TProject) => {
    const topologyStore = useProjectTopologyStore(project);
    const { topology } = storeToRefs(topologyStore);
    const routers = computed(() =>
        Object.values(topology.value.routers).filter((r) =>
            ['running', 'deleting', 'degraded'].includes(r.status.toLowerCase())
        )
    );

    return routers;
};
